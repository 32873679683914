<template>
  <v-form @submit.prevent="submitResponse">
    <v-radio-group
      v-model="inputs.selectedOptionKey"
      :label="task.content.config.question"
      class="pl-3"
    >
      <template #label>
        <stemble-latex :content="task.content.config.question" style="white-space: pre-wrap" />
      </template>
      <v-radio v-for="option in options" :key="option.key" :value="option.key">
        <template #label>
          <stemble-latex :content="option.label" />
        </template>
      </v-radio>
    </v-radio-group>
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'MultipleChoice',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {selectedOptionKey: ''},
    };
  },
  computed: {
    options() {
      let {randomizeOptionOrder, options} = this.task.content.config;

      if (!randomizeOptionOrder) {
        return options;
      }

      return seededShuffle(options, this.seed);
    },
    seed() {
      return this.$gate.user?.id || 1;
    },
  },
};
</script>
